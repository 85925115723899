<ng-container>
  <!-- <div *ngIf="root && item.visible !== false" class="layout-menuitem-root-text">{{item.label}}</div> -->
  <a
    *ngIf="(!item.routerLink || item.items) && item.visible !== false"
    (click)="itemClick($event)"
    [ngClass]="item.class"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{ item.label }}</span>
    <i
      class="pi pi-fw pi-angle-down layout-submenu-toggler"
      *ngIf="item.items"
    ></i>
  </a>

  <a
    *ngIf="item.routerLink && !item.items && item.visible !== false"
    (click)="itemClick($event)"
    [ngClass]="item.class"
    [routerLink]="item.routerLink"
    routerLinkActive="active-route"
    [routerLinkActiveOptions]="
      item.routerLinkActiveOptions || {
        paths: 'exact',
        queryParams: 'ignored',
        matrixParams: 'ignored',
        fragment: 'ignored'
      }
    "
    [fragment]="item.fragment"
    [queryParamsHandling]="item.queryParamsHandling"
    [preserveFragment]="item.preserveFragment"
    [skipLocationChange]="item.skipLocationChange"
    [replaceUrl]="item.replaceUrl"
    [state]="item.state"
    [queryParams]="item.queryParams"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{ item.label }}</span>
    <i
      class="pi pi-fw pi-angle-down layout-submenu-toggler"
      *ngIf="item.items"
    ></i>
  </a>

  <ul
    *ngIf="item.items && item.visible !== false"
    [@children]="submenuAnimation"
  >
    @for (child of item.items; track $index) {
    <!-- @if (child.permission) {
    permission1:
    <div class="text-gray-500">
      {{ child.permission }}
    </div>
    <rpp-valid-auth-wrapper [permissionKey]="child.permission" type="general">
      <div class="text-gray-500">adadasd</div>
    </rpp-valid-auth-wrapper>
    } @else {
    <div class="text-gray-500">
      {{ child.permission }}
    </div>
    <li
      rpp-menuitem
      [item]="child"
      [index]="$index"
      [parentKey]="key"
      [class]="child.badgeClass"
    ></li>
    } -->
    <li
      rpp-menuitem
      [item]="child"
      [index]="$index"
      [parentKey]="key"
      [class]="child.badgeClass"
    ></li>
    <!-- <div class="text-gray-500">
      {{ child.permission }}
    </div> -->
    }
  </ul>
</ng-container>
